<template>
  <div>
    <van-nav-bar title="驹式标准洗车" :z-index="999" :fixed="true" left-arrow @click-left="back" />
    <div class="mian">
      <img class="zdimg" src="../../assets/newimg/xichetop.png" alt />
      <img class="xcbiao" src="../../assets/newimg/xichebiao.png" alt />
      <div class="xczuo">不伤车</div>
      <div class="lis">
        <img class="xizuo left" src="../../assets/newimg/xic1.jpg" alt />
        <div
          class="left wenzi"
        >全自动电脑洗车能自动闪避照后镜等，确保汽车安全；电脑洗车洗净力强、含水量大、刷压力均匀不伤车，对本身油漆的磨损程度为手工洗车的30%以下。</div>
      </div>
      <div class="xczuo ens">磨损小</div>
      <div class="lis">
        <img class="xizuo left" src="../../assets/newimg/xic2.jpg" alt />
        <div class="left wenzi">测试结果表明：电脑洗车50次后车身油 漆磨损小于万分之三毫米，而人工洗车 磨损大于万分之十毫米。</div>
      </div>
      <!-- <div class="xcanniu">新车一键领取</div> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: []
    };
  },

  methods: {
    back() {
      this.$router.go(-1);
    }
  },
  created() {}
};
</script>

<style lang="less" scoped>
.zdimg {
  width: 100%;
  display: block;
}
.mian {
  padding-top: 46px;
   padding-bottom: 40px;
}
.xcbiao {
  display: block;
  width: 152px;
  height: 22px;
  margin: auto;
  margin-top: 18px;
 
}
.xczuo {
  background: url(../../assets/newimg/xichezuo.png);
  background-size: 100%;
  width: 96px;
  height: 24px;
  margin-top: 20px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}
.ens {
  margin-top: 30px;
}
.lis {
  overflow: hidden;
  margin-top: 18px;
}
.xizuo {
  width: 120px;
  height: 81px;
}
.wenzi {
  margin-left: 15px;
  width: 210px;
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  //   font-weight: 400;
  text-align: left;
  color: #666666;
  line-height: 17px;
  margin-top: 6px;
}
.xcanniu {
  width: 156px;
  height: 44px;
  background: url(../../assets/newimg/xcanniu.png);
  background-size: 100%;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #6f4600;
  line-height: 44px;
  text-align: center;
  margin: auto;
  margin-top: 30px;
}
</style>
